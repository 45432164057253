import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import config from '../config';
import { Play, Loader } from 'lucide-react';

const SharedVideoPage = () => {
  // hello
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/processed-video-details/${videoId}`);
        if (response.status === 200) {
          setVideoData(response.data);
          setVideoUrl(`${config.API_BASE_URL}/stream-video/${videoId}`);
          setThumbnailUrl(`${config.API_BASE_URL}/thumbnail/${videoId}`);
        } else {
          setError('Failed to fetch video details');
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
        setError('An error occurred while fetching video details');
      }
    };

    fetchVideoData();
  }, [videoId]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  if (error) return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-br from-indigo-50 to-purple-50">
      <p className="text-xl text-gray-700">{error}</p>
    </div>
  );

  if (!videoData) return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-br from-indigo-50 to-purple-50">
      <Loader className="w-12 h-12 text-indigo-600 animate-spin" />
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-purple-50 flex flex-col">
      <header className="bg-white shadow-sm">
        <div className="container mx-auto px-6 py-4">
          <Link to="/" className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text">Loomos</Link>
        </div>
      </header>
      <main className="flex-grow container mx-auto px-6 py-12 flex flex-col items-center">
        <motion.div 
          className="w-full max-w-2xl bg-white rounded-2xl shadow-xl overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative" style={{ paddingTop: '56.25%' }}>
            <video 
              ref={videoRef}
              src={videoUrl} 
              controls 
              className="absolute inset-0 w-full h-full object-cover"
              style={{ display: isPlaying ? 'block' : 'none' }}
            />
            {!isPlaying && (
              <div className="absolute inset-0">
                <img src={thumbnailUrl} alt="Video thumbnail" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  <motion.button
                    className="w-20 h-20 bg-white bg-opacity-80 rounded-full flex items-center justify-center"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handlePlayClick}
                  >
                    <Play className="w-10 h-10 text-indigo-600" />
                  </motion.button>
                </div>
              </div>
            )}
          </div>
          <div className="p-4">
            <h1 className="text-2xl font-bold mb-4 text-gray-800">{videoData.title} ✨</h1>
            <p className="text-sm text-gray-600 italic">Having trouble playing the video? Try viewing it on a laptop/desktop browser for now.</p>
          </div>
        </motion.div>
        <motion.div 
          className="mt-12 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Create stunning product videos in minutes 🚀</h2>
          <Link to="/">
            <motion.button
              className="px-8 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full font-semibold shadow-lg hover:shadow-xl transition-all duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Start your free trial today! 🎉
            </motion.button>
          </Link>
          <p className="mt-4 text-sm text-gray-600">No credit card required</p>
        </motion.div>
      </main>
      <footer className="bg-white py-6">
        <div className="container mx-auto px-6 text-center text-gray-600">
          <p className="mt-2 text-sm">Made with ❤️ using Loomos</p>
        </div>
      </footer>
    </div>
  );
};

export default SharedVideoPage;