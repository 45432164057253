import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactPlayer from 'react-player';
import config from '../config';
import axios from 'axios';
import { Play, Pause, Volume2, VolumeX, Maximize, Minimize, AlertCircle, Loader } from 'lucide-react';

const OverlayVideoPlayer = ({ user, videoId, backgroundImageUrl, className, isPaused, selectedMusicId, musicVolumePercentage}) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(!isPaused);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [videoError, setVideoError] = useState(null);
  const [musicError, setMusicError] = useState(null);

  const [videoVolume, setVideoVolume] = useState(1);
  const musicAudioRef = useRef(null);
  const [musicLoaded, setMusicLoaded] = useState(false);
  const [currentMusicId, setCurrentMusicId] = useState(null);
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        setVideoError(null);
        setVideoReady(false);
        const idToken = await user.getIdToken();
        const response = await axios.get(`${config.API_BASE_URL}/stream-video/${videoId}`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
          responseType: 'blob'
        });
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
        setVideoReady(true);
      } catch (error) {
        console.error('Error loading video:', error);
        setVideoError('Failed to load video. Please try again.');
      }
    };

    loadVideo();
    return () => {
      if (videoUrl) URL.revokeObjectURL(videoUrl);
    };
  }, [user, videoId]);

  useEffect(() => {
    setIsPlaying(!isPaused);
  }, [isPaused]);

  useEffect(() => {
    const loadMusic = async () => {
      if (selectedMusicId && selectedMusicId !== currentMusicId) {
        try {
          setMusicError(null);
          const idToken = await user.getIdToken();
          const response = await axios.get(`${config.API_BASE_URL}/stream-music/${selectedMusicId}`, {
            headers: { 'Authorization': `Bearer ${idToken}` },
            responseType: 'blob'
          });
          const musicUrl = URL.createObjectURL(response.data);

          // Clean up previous musicAudio
          if (musicAudioRef.current) {
            musicAudioRef.current.pause();
            musicAudioRef.current.oncanplaythrough = null;
            musicAudioRef.current.onerror = null;
            musicAudioRef.current.src = '';
            musicAudioRef.current = null;
          }

          const newAudio = new Audio(musicUrl);
          newAudio.loop = true;
          const safeVolume = Math.min(Math.max((videoVolume * (musicVolumePercentage || 100)) / 100, 0), 1);
          newAudio.volume = isFinite(safeVolume) ? safeVolume : 0;

          newAudio.oncanplaythrough = () => {
            setMusicLoaded(true);
            if (isPlaying && videoReady) {
              newAudio.play().catch(console.error);
            }
          };

          newAudio.onerror = (e) => {
            console.error('Music playback error:', e);
            setMusicError('An error occurred during music playback. Please try again.');
          };

          musicAudioRef.current = newAudio;
          setCurrentMusicId(selectedMusicId);
          setMusicLoaded(false);
        } catch (error) {
          console.error('Error loading music:', error);
          setMusicError('Failed to load music. Please try again.');
        }
      } else if (!selectedMusicId) {
        // No selected music, clean up
        if (musicAudioRef.current) {
          musicAudioRef.current.pause();
          musicAudioRef.current.oncanplaythrough = null;
          musicAudioRef.current.onerror = null;
          musicAudioRef.current.src = '';
          musicAudioRef.current = null;
        }
        setMusicLoaded(false);
        setCurrentMusicId(null);
      }
    };

    loadMusic();

    return () => {
      if (musicAudioRef.current) {
        musicAudioRef.current.pause();
        musicAudioRef.current.oncanplaythrough = null;
        musicAudioRef.current.onerror = null;
        musicAudioRef.current.src = '';
        musicAudioRef.current = null;
      }
    };
  }, [selectedMusicId, user]);

  useEffect(() => {
    const musicAudio = musicAudioRef.current;
    if (musicAudio && musicLoaded && videoReady) {
      if (isPlaying) {
        musicAudio.play().catch(error => {
          console.error('Error playing music:', error);
          setMusicError('Failed to play music. Please try again.');
        });
      } else {
        musicAudio.pause();
      }
    }
  }, [isPlaying, musicLoaded, videoReady]);

  useEffect(() => {
    const musicAudio = musicAudioRef.current;
    if (musicAudio) {
      const safeVolume = Math.min(Math.max((videoVolume * (musicVolumePercentage || 100)) / 100, 0), 1);
      musicAudio.volume = isFinite(safeVolume) ? safeVolume : 0;
    }
  }, [videoVolume, musicVolumePercentage]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVideoVolume(newVolume);
    setVolume(newVolume);
  };

  const handleToggleMute = () => {
    setMuted(!muted);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setPlayed(newValue);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    const newValue = parseFloat(e.target.value);
    playerRef.current.seekTo(newValue);
    const musicAudio = musicAudioRef.current;
    if (musicAudio) {
      musicAudio.currentTime = newValue * duration;
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = useCallback(({ played }) => {
    if (!seeking) {
      setPlayed(played);
    }
  }, [seeking]);

  const handleToggleFullscreen = useCallback(() => {
    const element = containerRef.current;
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
    } else {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const handleCanvasClick = () => {
    handlePlayPause();
  };

  const handleMouseEnter = () => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setShowControls(false);
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds)) {
      return '00:00';
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
    const musicAudio = musicAudioRef.current;
    if (musicAudio) {
      musicAudio.pause();
      musicAudio.currentTime = 0;
    }
  }, []);

  const handleVideoError = (e) => {
    console.error('Video playback error:', e);
    setVideoError('An error occurred during video playback. Please try again.');
  };

  const renderControls = () => (
    <div className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-4 transition-opacity duration-300 ${showControls ? 'opacity-100' : 'opacity-0'}`}
         onClick={(e) => e.stopPropagation()}>
      <div className="flex flex-col text-white">
        <input
          type="range"
          min={0}
          max={0.999999}
          step="any"
          value={played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          onTouchEnd={handleSeekMouseUp}
          className="w-full cursor-pointer appearance-none bg-white/30 h-1 rounded-full outline-none transition-all duration-150 ease-in-out hover:bg-white/50"
          style={{
            background: `linear-gradient(to right, white ${played * 100}%, rgba(255, 255, 255, 0.3) ${played * 100}%)`,
          }}
        />
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <button onClick={handlePlayPause} className="focus:outline-none mr-4 hover:text-gray-300 transition-colors duration-150">
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </button>
            <button onClick={handleToggleMute} className="focus:outline-none mr-2 hover:text-gray-300 transition-colors duration-150">
              {muted ? <VolumeX size={24} /> : <Volume2 size={24} />}
            </button>
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={handleVolumeChange}
              className="w-20 cursor-pointer appearance-none bg-white/30 h-1 rounded-full outline-none transition-all duration-150 ease-in-out hover:bg-white/50"
              style={{
                background: `linear-gradient(to right, white ${volume * 100}%, rgba(255, 255, 255, 0.3) ${volume * 100}%)`,
              }}
            />
            <span className="ml-4 text-sm">{formatTime(played * duration)} / {formatTime(duration)}</span>
          </div>
          <button onClick={handleToggleFullscreen} className="focus:outline-none hover:text-gray-300 transition-colors duration-150">
            {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div 
      ref={containerRef} 
      className={`relative w-full ${className}`} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div 
        className={`relative w-full pb-[56.25%] ${backgroundImageUrl ? 'bg-cover bg-center' : ''}`}
        style={backgroundImageUrl ? { backgroundImage: `url(${backgroundImageUrl})` } : {}}
        onClick={handleCanvasClick}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <div className={`${backgroundImageUrl ? 'w-[90%] h-[90%]' : 'w-full h-full'} relative`}>
            {videoError ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500">
                <div className="text-center">
                  <AlertCircle size={48} className="mx-auto mb-4 text-red-500" />
                  <p>{videoError}</p>
                </div>
              </div>
            ) : !videoReady ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500">
                <div className="text-center">
                  <Loader size={48} className="mx-auto mb-4 animate-spin" />
                  <p>Video is loading...</p>
                </div>
              </div>
            ) : (
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                playing={isPlaying && videoReady}
                volume={volume}
                muted={muted}
                onProgress={handleProgress}
                onDuration={handleDuration}
                width="100%"
                height="100%"
                style={{ backgroundColor: 'transparent' }}
                onReady={() => {
                  setVideoReady(true);
                  setIsPlaying(true);
                }}
                onPlay={() => {
                  setIsPlaying(true);
                }}
                onPause={() => {
                  setIsPlaying(false);
                }}
                onEnded={handleVideoEnd}
                onError={handleVideoError}
                config={{
                  file: {
                    forceVideo: true,
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      {renderControls()}
      {musicError && (
        <div className="absolute top-0 left-0 right-0 bg-red-500 text-white p-2 text-center">
          {musicError}
        </div>
      )}
    </div>
  );
};

export default React.memo(OverlayVideoPlayer);
