import React, { useState, useEffect } from 'react';
import { PlusCircle, Library, LogOut, PlayCircle, MessageSquare, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthProvider';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const MagicWandIcon = ({ className = '', size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 120 120"
    width={size}
    height={size}
    className={className}
  >
    <g transform="rotate(90, 50, 50)">
      <path
        d="M50 15 L50 85 M15 50 L85 50 M25 25 L75 75 M75 25 L25 75"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M75 75 L95 95"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const SidePanel = () => {
  const { user, handleGoogleSignIn, handleSignOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    if (user) {
      fetchUserSubscription();
    }
  }, [user]);

  const fetchUserSubscription = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/user-subscription`, {
        headers: { Authorization: `Bearer ${idToken}` }
      });
      setSubscription(response.data);
    } catch (error) {
      console.error('Failed to fetch user subscription:', error);
    }
  };

  const handleLogout = () => {
    handleSignOut();
    navigate('/login');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getPlanName = (tier) => {
    switch (tier) {
      case 'free(with Watermark)':
        return 'Free Plan';
      case 'starter':
        return 'Starter';
      case 'pro':
        return 'Pro';
      case 'pro-max':
        return 'Pro Max';
      default:
        return 'Unknown Plan';
    }
  };

  return (
    <div className="w-64 bg-white shadow-lg text-gray-800 h-screen p-6 flex flex-col">
      <h1 className="text-3xl font-bold mb-8 flex items-center">
        <MagicWandIcon size={42} className="text-purple-600 mr-2" />
        <span className="bg-gradient-to-r from-purple-600 via-indigo-600 to-blue-500 text-transparent bg-clip-text">
          loomos
        </span>
      </h1>
      <nav className="space-y-2">
        {[
          { name: 'New Project', icon: PlusCircle, path: '/' },
          { name: 'My Library', icon: Library, path: '/library' },
          { name: 'Tutorial', icon: PlayCircle, path: '/tutorial' },
          { name: 'Feedback', icon: MessageSquare, path: '/feedback' }, // Add this line
        ].map((item) => (
          <Link
            key={item.name}
            to={item.path}
            className={`flex items-center space-x-3 w-full p-3 rounded-lg ${
              location.pathname === item.path 
                ? 'bg-gradient-to-r from-purple-100 to-indigo-100 text-indigo-700' 
                : 'hover:bg-gray-100'
            }`}
          >
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
              className="flex items-center space-x-3 w-full"
            >
              <item.icon size={20} />
              <span className="font-medium">{item.name}</span>
            </motion.div>
          </Link>
        ))}
      </nav>
      <div className="flex-grow" />
      {user && subscription && (
        <div className="mt-6 mb-4 bg-gradient-to-br from-purple-50 to-indigo-50 rounded-lg p-4 shadow-sm">
          <h3 className="text-xs font-semibold text-indigo-600 uppercase tracking-wide mb-2">Current Plan</h3>
          <div className="flex justify-between items-center mb-2">
            <span className="text-lg font-bold text-gray-800">{getPlanName(subscription.tier)}</span>
            <span className="text-sm font-medium text-indigo-600">{subscription.videos_left} videos left</span>
          </div>        
          <motion.button
            className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded-md text-sm font-medium flex items-center justify-center shadow-sm hover:shadow-md transition-shadow duration-150 ease-in-out"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/upgrade')}
          >
            {'Upgrade Plan'}
            <ChevronRight size={16} className="ml-1" />
          </motion.button>
        </div>
      )}
      {user ? (
        <motion.button
          className="flex items-center space-x-3 w-full p-3 rounded-lg hover:bg-gray-100 text-gray-700"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleLogout}
        >
          <LogOut size={20} />
          <span className="font-medium">Log Out</span>
        </motion.button>
      ) : (
        <motion.button
          className="flex items-center space-x-3 w-full p-3 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 text-white"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleGoogleSignIn}
        >
          <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-5 h-5" />
          <span className="font-medium">Sign in with Google</span>
        </motion.button>
      )}
    </div>
  );
};

export default SidePanel;