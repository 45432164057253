import React, { useCallback, useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wand2, Loader, Globe, Edit3, Info, RotateCcw, ChevronDown } from 'lucide-react';
import axios from 'axios';
import config from '../config';

const TranscriptTab = ({ 
  user, 
  transcript, 
  setTranscript, 
  isTranslating, 
  setIsTranslating, 
  selectedLanguage, 
  setSelectedLanguage, 
  languages, 
  uploadedVideoId, 
  onConfigChange,
  originalTranscript,  
}) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleTranscriptChange = (index, newText) => {
    const updatedTranscript = [...transcript];
    updatedTranscript[index].text = newText;
    setTranscript(updatedTranscript);
    onConfigChange();
  };

  const [isImproving, setIsImproving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showImproveTooltip, setShowImproveTooltip] = useState(false);
  const [showEditTooltip, setShowEditTooltip] = useState(false);
  const [showTranslateTooltip, setShowTranslateTooltip] = useState(false);
  const [showRevertTooltip, setShowRevertTooltip] = useState(false);
  const [isReverting, setIsReverting] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageChange = async (newLanguage) => {
    setSelectedLanguage(newLanguage);
    onConfigChange();

    if (newLanguage) {
      setIsTranslating(true);
      try {
        const idToken = await user.getIdToken();
        const response = await axios.post(`${config.API_BASE_URL}/get-translation`, {
          video_id: uploadedVideoId,
          language: parseInt(newLanguage),
          segments: transcript
        }, {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        
        if (response.data && response.data.transcript) {
          setTranscript(response.data.transcript);
        }
      } catch (error) {
        console.error('Error translating transcript:', error);
        // Show error message to the user
      } finally {
        setIsTranslating(false);
      }
    }
  };

  const handleImproveWithAI = useCallback(async () => {
    setIsImproving(true);
    try {
      const idToken = await user.getIdToken();
      const response = await axios.post(`${config.API_BASE_URL}/improve-with-ai`, {
        segments: transcript
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      
      if (response.data && response.data.improved_segments) {
        setTranscript(response.data.improved_segments);
        onConfigChange();
      }
    } catch (error) {
      console.error('Error improving transcript with AI:', error);
      // Show error message to the user
    } finally {
      setIsImproving(false);
    }
  }, [user, transcript, setTranscript, onConfigChange]);

  const handleRevertToOriginal = async () => {
    setIsReverting(true);
    try {
      const idToken = await user.getIdToken();
      const response = await axios.post(`${config.API_BASE_URL}/get-translation`, {
        video_id: uploadedVideoId,
        language: 0, // original language
        segments: transcript
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      
      if (response.data && response.data.transcript) {
        setTranscript(response.data.transcript);
        setSelectedLanguage(0); // Reset selected language to default
        onConfigChange(); // Call this to trigger the "Update Preview" button
      }
    } catch (error) {
      console.error('Error reverting to original transcript:', error);
      // Show error message to the user
    } finally {
      setIsReverting(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const Tooltip = ({ show, children }) => (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.2 }}
          className="absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-800 rounded-md shadow-lg"
        >
          <div className="flex items-start">
            <Info className="flex-shrink-0 w-5 h-5 mr-2" />
            <p>{children}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const buttonClass = "flex-1 py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 shadow-sm text-sm font-medium";
  const primaryButtonClass = `${buttonClass} bg-blue-100 text-blue-700 hover:bg-blue-200 focus:ring-blue-300`;
  const editButtonClass = `${buttonClass} bg-green-50 text-green-700 hover:bg-green-100 focus:ring-green-200`;
  const translateButtonClass = `${buttonClass} bg-purple-50 text-purple-700 hover:bg-purple-100 focus:ring-purple-200`;
  const revertButtonClass = `${buttonClass} bg-red-50 text-red-700 hover:bg-red-100 focus:ring-red-200`;

  const handleAddTranscript = () => {
    setIsEditing(true);
  };

  return (
    <div className="flex flex-col h-full bg-white p-6 rounded-lg shadow-lg">
      <div className="flex items-center space-x-4 mb-6">
        <div className="relative flex-1">
          <motion.button
            className={`${primaryButtonClass} ${isImproving ? 'opacity-75 cursor-not-allowed' : ''}`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleImproveWithAI}
            disabled={isImproving}
            onMouseEnter={() => setShowImproveTooltip(true)}
            onMouseLeave={() => setShowImproveTooltip(false)}
          >
            {isImproving ? <Loader className="mr-2 h-4 w-4 animate-spin text-blue-700" /> : <Wand2 className="mr-2 h-4 w-4 text-blue-700" />}
            <span>{isImproving ? 'Improving...' : 'Improve with AI'}</span>
          </motion.button>
          <Tooltip show={showImproveTooltip}>
            This will improve clarity, and refine the language for better understanding.
          </Tooltip>
        </div>
        <div className="relative flex-1">
          <motion.button
            className={editButtonClass}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setIsEditing(!isEditing)}
            onMouseEnter={() => setShowEditTooltip(true)}
            onMouseLeave={() => setShowEditTooltip(false)}
          >
            <Edit3 className="mr-2 h-4 w-4 text-green-600" />
            <span>{isEditing ? 'Save Edits' : 'Edit Transcript'}</span>
          </motion.button>
          <Tooltip show={showEditTooltip}>
            {isEditing ? 'Save your manual edits to the transcript' : 'Make manual edits to the transcript'}
          </Tooltip>
        </div>
        <div className="relative flex-1" ref={dropdownRef}>
          <motion.button
            className={`${translateButtonClass} w-full ${isTranslating ? 'opacity-75 cursor-not-allowed' : ''}`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={toggleDropdown}
            disabled={isTranslating}
            onMouseEnter={() => setShowTranslateTooltip(true)}
            onMouseLeave={() => setShowTranslateTooltip(false)}
          >
            <Globe className="mr-2 h-4 w-4 text-purple-600" />
            <span>{isTranslating ? 'Translating...' : (selectedLanguage ? languages.find(l => l.id === parseInt(selectedLanguage)).name : 'Translate')}</span>
            <ChevronDown className="ml-2 h-4 w-4 text-purple-600" />
          </motion.button>
          <Tooltip show={showTranslateTooltip}>
            Translate the transcript to a different language
          </Tooltip>
          {isDropdownOpen && (
            <div className="absolute z-10 mt-2 w-full bg-white rounded-md shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
              {languages.map(lang => (
                <button
                  key={lang.id}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition duration-150 ease-in-out"
                  onClick={() => {
                    handleLanguageChange(lang.id.toString());
                    setIsDropdownOpen(false);
                  }}
                >
                  {lang.name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex-grow bg-gray-50 rounded-md border border-gray-200 relative overflow-hidden shadow-inner mb-4" style={{ height: 'calc(100vh - 28rem)' }}>
        <div className="p-4 bg-transparent rounded-md focus:outline-none resize-none overflow-y-auto" style={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(100vh - 28rem)' }}>
          {transcript.length === 0 || (transcript.length === 1 && transcript[0].text.trim() === '') && !isEditing ? (
            <div className="text-center text-gray-500 mt-8">
              <p>No Audio available in Video. Click edit to add your own transcript.</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md"
                onClick={handleAddTranscript}
              >
                <Edit3 size={18} className="inline mr-2" />
                Add Transcript
              </motion.button>
            </div>
          ) : (
            transcript.map((para, index) => (
              <div key={index} className="mb-6">
                {isEditing && (
                  <div className="text-xs text-gray-400 mb-2 font-medium">{formatTime(para.start)} - {formatTime(para.end)}</div>
                )}
                <div
                  className={`text-gray-800 p-3 rounded transition duration-150 ease-in-out font-serif text-lg leading-relaxed ${
                    isEditing 
                      ? 'bg-white border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                      : 'bg-transparent'
                  }`}
                  contentEditable={isEditing}
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleTranscriptChange(index, e.target.textContent)}                  
                >
                  {para.text}
                </div>
              </div>
            ))
        )}
        </div>
        {(isTranslating || isImproving) && (
          <div className="absolute inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center backdrop-filter backdrop-blur-sm">
            <Loader className="h-12 w-12 text-purple-600 animate-spin" />
          </div>
        )}
      </div>
      <div className="mt-4">
        <motion.button
          className={`${revertButtonClass} w-full ${isReverting ? 'opacity-75 cursor-not-allowed' : ''}`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleRevertToOriginal}
          disabled={isReverting}
          onMouseEnter={() => setShowRevertTooltip(true)}
          onMouseLeave={() => setShowRevertTooltip(false)}
        >
          {isReverting ? (
            <Loader className="mr-2 h-4 w-4 animate-spin text-red-600" />
          ) : (
            <RotateCcw className="mr-2 h-4 w-4 text-red-600" />
          )}
          <span>{isReverting ? 'Reverting...' : 'Get Original Transcript'}</span>
        </motion.button>
        <AnimatePresence>
          {showRevertTooltip && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 text-sm text-white bg-gray-800 rounded-md shadow-lg z-10"
            >
              <div className="flex items-start">
                <Info className="flex-shrink-0 w-5 h-5 mr-2" />
                <p>Reset the transcript to its original state, discarding all changes</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TranscriptTab;