import React, { useState, useEffect, useRef } from 'react';
import { ChevronRight, Globe, Mic, Image, ArrowRight, Play, Pause, Edit, Palette, Maximize2, Minimize2, Menu, X, Check } from 'lucide-react';
import { useAuth } from '../AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import VideoComparison from './VideoComparison';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './LandingPage.css'; // Add this import at the top of the file
import axios from 'axios';
import config from '../config';
import useTawkTo from '../hooks/useTawkTo';

const MagicWandIcon = ({ className = '', size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 120 120"
    width={size}
    height={size}
    className={className}
  >
    <g transform="rotate(90, 50, 50)">
      <path
        d="M50 15 L50 85 M15 50 L85 50 M25 25 L75 75 M75 25 L25 75"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M75 75 L95 95"
        stroke="currentColor"
        strokeWidth="10" 
        strokeLinecap="round"
      />
    </g>
  </svg>
);

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-arrow-next`}
      onClick={onClick}
    />
  );
}

const LandingPage = () => {
  const { handleGoogleSignIn } = useAuth();
  const navigate = useNavigate();  
  const beforeVideoRef = useRef(null);
  const afterVideoRef = useRef(null);
  const [currentUseCase, setCurrentUseCase] = useState(0);
  const useCases = [
    "Product Demos",
    "Training Videos",
    "How-to Guides"
  ];

  const [isWiderView, setIsWiderView] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [plans, setPlans] = useState([]);
  const { user } = useAuth();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useTawkTo();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const idToken = await user?.getIdToken();
        const plansResponse = await axios.get(`${config.API_BASE_URL}/subscription-plans`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        const sortedPlans = plansResponse.data.plans.sort((a, b) => {
          if (a.name.toLowerCase().includes('free')) return -1;
          if (b.name.toLowerCase().includes('free')) return 1;
          return a.price - b.price;
        });
        setPlans(sortedPlans);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
      }
    };

    fetchPlans();
  }, [user]);

  const getPlanName = (tier) => {
    switch (tier.toLowerCase()) {
      case 'free(with watermark)':
        return 'Free Plan';
      case 'starter':
        return 'Starter';
      case 'pro':
        return 'Pro';
      case 'pro-max':
        return 'Pro Max';
      default:
        return tier;
    }
  };

  useEffect(() => {
    // Update font loading to include Poppins
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap'; // Add the font link
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Apply font to body
    document.body.style.fontFamily = "'Poppins', sans-serif";

    // Cleanup
    return () => {
      document.head.removeChild(link);
      document.body.style.fontFamily = '';
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUseCase((prev) => (prev + 1) % useCases.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Initialize scrollSpy
    Events.scrollEvent.register('begin', function() {
      // Remove console.log
    });

    Events.scrollEvent.register('end', function() {
      // Remove console.log
    });

    scrollSpy.update();

    // Cleanup
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    // Add meta tags for SEO
    document.title = "Create Stunning Videos with Loomos | AI Video Editing, Multilingual Support, and Easy Sharing";
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Transform your screen recordings into polished, multilingual videos with Loomos. Experience AI-powered video editing, easy sharing, and no editing skills required!";
    document.head.appendChild(metaDescription);

    // Cleanup
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  useEffect(() => {
    // Add Google Analytics script
    const gtagScript1 = document.createElement('script');
    gtagScript1.async = true;
    gtagScript1.src = 'https://www.googletagmanager.com/gtag/js?id=G-X3QPY1M2BF';
    
    const gtagScript2 = document.createElement('script');
    gtagScript2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-X3QPY1M2BF');
    `;

    document.head.appendChild(gtagScript1);
    document.head.appendChild(gtagScript2);

    // Cleanup
    return () => {
      document.head.removeChild(gtagScript1);
      document.head.removeChild(gtagScript2);
    };
  }, []);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endDate = new Date('2024-12-01T00:00:00'); // Set your sale end date
      const difference = endDate - new Date();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignIn = async () => {
    try {
      await handleGoogleSignIn();
      // Track successful login
      if (window.gtag) {
        window.gtag('event', 'login', {
          'method': 'Google',
          'success': true
        });
      }
      navigate('/');
    } catch (error) {
      console.error('Error signing in:', error);
      // Track failed login
      if (window.gtag) {
        window.gtag('event', 'login', {
          'method': 'Google',
          'success': false,
          'error': error.message
        });
      }
    }
  };

  const languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'hi', name: 'Hindi', flag: '🇮🇳' },
    { code: 'es', name: 'Spanish', flag: '🇪🇸' },
    { code: 'fr', name: 'French', flag: '🇫🇷' },
  ];

  const beforeVideoSrc = `${process.env.PUBLIC_URL}/demomedia/before/sheet.mp4`;
  const beforeThumbnailSrc = `${process.env.PUBLIC_URL}/demomedia/before/sheet.jpg`;
  const getAfterVideoSrc = (langCode) => `${process.env.PUBLIC_URL}/demomedia/after/${langCode}-sheet.mp4`;
  const getAfterThumbnailSrc = (langCode) => `${process.env.PUBLIC_URL}/demomedia/after/en-sheet-thumbnail.jpg`;

  const features = [
    {
      icon: Edit,
      title: "AI-Powered Transcript Editing",
      desc: "Edit your transcript yourself, or use AI to generate an improved version automatically. Our AI cleans up 'uhms' and improves grammar for a polished result.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/transcript.mp4`
    },
    {
      icon: Image,
      title: "Aesthetic Background Images",
      desc: "Enhance your videos with beautiful background images to create a polished look.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/bg.gif`
    },
    {
      icon: Globe,
      title: "Multilingual Translation & AI Voiceovers",
      desc: "Translate to multiple languages and select from a variety of AI voiceovers that sound professional yet human-like, with different accents available.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/voice.gif`
    }
  ];

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    customPaging: function(i) {
      return (
        <div className="custom-dot"></div>
      );
    },
  };

  const fadeInOut = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.5 }
  };

  const PricingSection = () => (
    <Element name="pricing">
      <section id="pricing" className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white px-8 py-4 rounded-2xl shadow-lg transform hover:scale-105 transition duration-300 border-2 border-yellow-400">
              <div className="bg-yellow-400 text-blue-900 px-4 py-1 rounded-full text-sm font-bold mb-3 inline-block">
                LIMITED TIME OFFER
              </div>
              <h3 className="text-2xl font-bold mb-2">🎉 Black Friday Special - 50% OFF! 🎉</h3>
              <p className="text-lg mb-3">Unlock Premium Features at the Best Price of the Year!</p>
              <div className="flex flex-col md:flex-row justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0 text-white">
                <div className="bg-blue-700/50 px-4 py-2 rounded-lg flex flex-col items-center hidden md:flex">
                  <span className="text-2xl font-bold">{timeLeft.days}</span>
                  <p className="text-sm">Days</p>
                </div>
                <div className="bg-blue-700/50 px-4 py-2 rounded-lg flex flex-col items-center hidden md:flex">
                  <span className="text-2xl font-bold">{timeLeft.hours}</span>
                  <p className="text-sm">Hours</p>
                </div>
                <div className="bg-blue-700/50 px-4 py-2 rounded-lg flex flex-col items-center hidden md:flex">
                  <span className="text-2xl font-bold">{timeLeft.minutes}</span>
                  <p className="text-sm">Minutes</p>
                </div>
                <div className="bg-blue-700/50 px-4 py-2 rounded-lg flex flex-col items-center hidden md:flex">
                  <span className="text-2xl font-bold">{timeLeft.seconds}</span>
                  <p className="text-sm">Seconds</p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold text-center mb-16 text-[#0f172a]">
            <span>Choose</span> the Perfect Plan for Your Needs
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {plans.map((plan, index) => (
              <div
                key={plan.id}
                className="bg-white rounded-lg shadow-lg p-8 flex flex-col relative overflow-hidden"
              >
                {!plan.name.toLowerCase().includes('free') && (
                  <div className="absolute -top-2 -right-2">
                    <div className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-blue-900 px-8 py-2 transform rotate-45 translate-x-8 translate-y--8 font-bold shadow-lg">
                      50% OFF
                    </div>
                  </div>
                )}
                <h3 className="text-2xl md:text-3xl font-bold mb-4 text-[#0f172a]">{getPlanName(plan.name)}</h3>
                <p className="text-4xl md:text-5xl font-bold mb-6 text-[#3b82f6]">
                  {!plan.name.toLowerCase().includes('free') && (
                    <>
                      <span className="text-2xl line-through text-gray-400 mr-2">
                        ${plan.price * 2}
                      </span>
                      <span className="text-blue-600">${plan.price}</span>
                    </>
                  )}
                  {plan.name.toLowerCase().includes('free') && `$${plan.price}`}
                  <span className="text-base font-normal text-[#64748b]">
                    {plan.name.toLowerCase().includes('free') ? '' : ' one-time payment'}
                  </span>
                </p>
                <ul className="mb-8 flex-grow">
                  {plan.description.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center mb-3">
                      <Check size={20} className="text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-[#334155] font-light">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button 
                  onClick={handleSignIn}
                  className={`w-full py-3 rounded-md font-semibold transition duration-300 ${
                    plan.name.toLowerCase().includes('free') 
                      ? "bg-[#3b82f6] text-white hover:bg-[#2563eb]"
                      : "bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800"
                  }`}
                >
                  {plan.name.toLowerCase().includes('free') ? "Get Started" : "Claim 50% Off Now"}
                </button>
                {!plan.name.toLowerCase().includes('free') && (
                  <div className="text-center mt-2">
                    <p className="text-sm font-semibold text-yellow-600">
                      Save ${plan.price} Today!
                    </p>
                    <p className="text-xs text-blue-600">
                      Black Friday Offer - Limited Time Only
                    </p>
                  </div>
                )}
              </div>
            ))}
            {/* Enterprise plan remains unchanged */}
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col">
              <h3 className="text-2xl md:text-3xl font-bold mb-4 text-[#0f172a]">Enterprise</h3>
              <p className="text-4xl md:text-5xl font-bold mb-6 text-[#3b82f6]">
                Custom
              </p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-3">
                  <Check size={20} className="text-green-500 mr-2 flex-shrink-0" />
                  <span className="text-[#334155] font-light">All Pro features</span>
                </li>
                <li className="flex items-center mb-3">
                  <Check size={20} className="text-green-500 mr-2 flex-shrink-0" />
                  <span className="text-[#334155] font-light">Custom integrations</span>
                </li>
                <li className="flex items-center mb-3">
                  <Check size={20} className="text-green-500 mr-2 flex-shrink-0" />
                  <span className="text-[#334155] font-light">Dedicated support</span>
                </li>
              </ul>
              <a 
                href="https://cal.com/loomos"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-[#3b82f6] text-white py-3 rounded-md font-semibold hover:bg-[#2563eb] transition duration-300 text-center"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );

  const VoiceCloningSection = () => (
    <section id="voice-cloning" className="py-24 bg-[#f0f4ff]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#1e293b]">
          Exciting News Coming This November!
        </h2>
        <p className="text-lg md:text-xl mb-4 text-[#334155]">
          We are launching a voice cloning feature that allows you to create final content or product demo videos in your own voice, with studio-grade quality. Remove the 'uhms' and the un-natural pauses from your transcript and make your videos a lot more engaging.
        </p>
        <p className="text-lg md:text-xl mb-4 text-[#334155]">
          This feature will be available for beta users this month. 
        </p>
        <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6pvYSPAohKPHgGLs03nhjWkLJFZKan8ANcrMBWe9YReYMEg/viewform?usp=sf_link" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block bg-[#3b82f6] text-white px-6 py-3 rounded-md font-semibold hover:bg-[#2563eb] transition duration-300"
        >
          Sign Up for Beta Access
        </a>
      </div>
    </section>
  );

  return (
    <div className="bg-white text-[#1e293b] min-h-screen overflow-x-hidden font-['Poppins',sans-serif] relative">
      {/* Navigation */}
      <motion.nav 
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/80 backdrop-blur-md shadow-md' : 'bg-transparent'
        }`}
      >
        <div className="max-w-custom mx-auto px-4 lg:px-6 lg:px-8"> {/* Updated class here */}
          <div className={`flex justify-between items-center transition-all duration-300 ${
            isScrolled ? 'py-4' : 'py-6'
          }`}>
            <div className="flex-shrink-0 flex items-center">
              <MagicWandIcon size={isScrolled ? 32 : 40} className="text-[#3b82f6]" />
              <h1 className={`font-bold bg-gradient-to-r from-[#3b82f6] to-[#2563eb] text-transparent bg-clip-text transition-all duration-300 ${
                isScrolled ? 'text-2xl' : 'text-3xl'
              }`}>
                loomos
              </h1>
            </div>
            <div className="hidden md:flex space-x-8">
              <ScrollLink 
                to="product-demo" 
                spy={true}
                smooth={true} 
                duration={500} 
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Product Demo
              </ScrollLink>
              <ScrollLink 
                to="features" 
                spy={true}
                smooth={true} 
                duration={500} 
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Features
              </ScrollLink>
              <ScrollLink 
                to="how-it-works" 
                spy={true}
                smooth={true} 
                duration={500}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                How it works
              </ScrollLink>
              <ScrollLink 
                to="pricing" 
                spy={true}
                smooth={true} 
                duration={500}
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Pricing
              </ScrollLink>
              <ScrollLink 
                to="voice-cloning"
                spy={true}
                smooth={true} 
                duration={500}
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Voice Cloning
              </ScrollLink>
              {/* <Link 
                to="/blog"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Blog
              </Link> */}
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="hidden md:block bg-[#3b82f6] text-white rounded-md font-semibold transition duration-300 hover:bg-[#2563eb] px-6 py-3" // This hides the button on mobile and tablet
                onClick={handleGoogleSignIn}
              >
                Login
              </button>
              <button
                className="md:hidden text-[#334155]"
                onClick={toggleMenu}
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="md:hidden bg-white py-4"
            >
              <div className="flex flex-col space-y-4 px-4">
                <ScrollLink 
                  to="product-demo" 
                  spy={true}
                  smooth={true} 
                  duration={500} 
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Product Demo
                </ScrollLink>
                <ScrollLink 
                  to="features" 
                  spy={true}
                  smooth={true} 
                  duration={500} 
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Features
                </ScrollLink>
                <ScrollLink 
                  to="how-it-works" 
                  spy={true}
                  smooth={true} 
                  duration={500}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  How it works
                </ScrollLink>
                <ScrollLink 
                  to="pricing" 
                  spy={true}
                  smooth={true} 
                  duration={500}
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Pricing
                </ScrollLink>
                <Link 
                  to="/blog"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Blog
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>

      {/* Add padding to the top of the first section to account for the fixed navbar */}
      <div className={`transition-all duration-300 ${isScrolled ? 'pt-8' : 'pt-12'}`}></div>

      {/* Company Logos Section */}
      <div className="pt-16 pb-8">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-8 md:gap-16">
            <a href="https://elevenlabs.io/" target="_blank" rel="noopener noreferrer">
              <img 
                src="/images/elevenlabs.png" 
                alt="ElevenLabs" 
                className="h-12 md:h-16 object-contain"
              />
            </a>
            <a href="https://www.producthunt.com/products/loomos/reviews" target="_blank" rel="noopener noreferrer">
              <img 
                src="/images/rating.png" 
                alt="Rating" 
                className="h-12 md:h-16 object-contain"
              />
            </a>
            <a href="https://www.producthunt.com/products/loomos" target="_blank" rel="noopener noreferrer">
              <img 
                src="/images/producthunt.png" 
                alt="Product Hunt" 
                className="h-12 md:h-16 object-contain"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <section className="py-12 md:py-16 overflow-hidden relative">
        <motion.div 
          initial="hidden"
          animate="visible"
          variants={staggerChildren}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10"
        >
          <motion.h1 
            variants={fadeIn} 
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 leading-tight tracking-tight text-[#1e293b]"
            style={{ lineHeight: '1.3' }} 
          >
            Create Stunning
            <br className="hidden sm:block" />
            <span className="text-[#3b82f6] inline-block h-[1.3em] overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.span
                  key={currentUseCase}
                  className="inline-block"
                  {...fadeInOut}
                >
                  {useCases[currentUseCase]}
                </motion.span>
              </AnimatePresence>
            </span>
            <br/>
            In Minutes.
          </motion.h1>
          <motion.p variants={fadeIn} className="text-xl md:text-2xl mb-14 text-[#64748b] max-w-3xl mx-auto font-normal"> {/* Increased text size, bottom margin, and max width */}
            Transform  
            <span className="font-bold text-[#3b82f6]"> boring screen recordings</span> into{' '}
            <span className="font-bold text-[#3b82f6]">
              studio-quality videos </span> in{' '}
            <span className="">
              single click. Edit the transcript, 
              <span className="font-bold text-[#3b82f6]"> remove all the 'uhms',</span> {' '}

              make videos a lot more engaging. Translate to 20+ languages.
            </span>            
            <br />                        
          </motion.p>
          <motion.div variants={fadeIn} className="flex flex-col items-center">
            <div className="flex justify-center space-x-6 mb-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleSignIn}
                className="bg-[#3b82f6] text-white px-8 py-4 rounded-md text-lg font-semibold hover:bg-[#2563eb] transition duration-300 inline-flex items-center"
              >
                Start creating for free              
              </motion.button>
              <ScrollLink
                to="product-demo"
                smooth={true}
                duration={500}
                offset={-100}
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white text-[#3b82f6] border-2 border-[#3b82f6] px-8 py-4 rounded-md text-lg font-semibold hover:bg-[#3b82f6] hover:text-white transition duration-300 inline-flex items-center"
                >
                  Watch Demo
                </motion.button>
              </ScrollLink>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4">
              <motion.p 
                variants={fadeIn}
                className="text-[#3b82f6] font-semibold text-lg bg-blue-100 px-4 py-2 rounded-full"
              >
                Pay per use. No subscriptions.
              </motion.p>
              <motion.p 
                variants={fadeIn}
                className="text-[#3b82f6] font-semibold text-lg bg-blue-100 px-4 py-2 rounded-full"
              >
                No editing skills or credit card required
              </motion.p>
            </div>
          </motion.div>
        </motion.div>
      </section>

      {/* Video Comparison Carousel */}
      <Element name="product-demo">
        <section id="product-demo" className="bg-white">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="slider-container relative"
          >              
            <div> {/* Removed padding class */}
              <div className="bg-white rounded-2xl overflow-hidden mb-8"> {/* Removed shadow-lg since it's full width */}
                <VideoComparison 
                  languages={languages}
                  beforeVideoSrc={beforeVideoSrc}
                  beforeThumbnailSrc={beforeThumbnailSrc}
                  getAfterVideoSrc={getAfterVideoSrc}
                  getAfterThumbnailSrc={getAfterThumbnailSrc}
                  title=""
                  titleBarColor="#f8fafc"
                  titleTextColor="#0f172a"
                  buttonColor="#3b82f6"
                  buttonTextColor="white"
                  buttonHoverColor="#2563eb"
                />
              </div>
            </div>                              
          </motion.div>          
        </section>
      </Element>

      {/* Horizontal Line */}
      {/* <hr className="max-w-custom mx-auto border-t border-gray-300 my-8" style={{ width: '85%' }} /> Ensure this line is styled correctly */}

      {/* Features Section */}
      <Element name="features">
        <section id="features" className="py-24 bg-white">
          <div className="max-w-6xl mx-auto px-2 sm:px-4 lg:px-6">
            <motion.h3 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold text-center mb-6 text-[#0f172a]"
            >
              <span className="text-[#3b82f6]">Create</span> Professional Product Videos Effortlessly
            </motion.h3>
            <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl md:text-2xl text-center mb-16 text-[#64748b] max-w-3xl mx-auto font-light">
            We are a one-stop platform where users can effortlessly produce professional, 
            studio-quality product demos, advertisements, and sales videos in just minutes. 
          </motion.p>
            <motion.div 
              variants={staggerChildren}
              initial="hidden"
              whileInView="visible"
              className="space-y-24"
            >
              {features.map((feature, index) => (
                <motion.div 
                  key={index} 
                  variants={fadeIn}
                  className={`flex flex-col md:flex-row items-center ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  }`}
                >
                  <div className={`w-full md:w-1/2 ${
                    index % 2 === 0 ? 'md:pr-12' : 'md:pl-12'
                  }`}>
                    <feature.icon className="w-12 h-12 text-indigo-600 mb-4" />
                    <h4 className="text-2xl md:text-3xl font-semibold mb-4 text-[#0f172a]">{feature.title}</h4>
                    <p className="text-lg text-[#334155] font-light">{feature.desc}</p>
                  </div>
                  <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <div className="rounded-lg overflow-hidden shadow-lg">
                      {feature.media.endsWith('.gif') ? (
                        <img 
                          src={feature.media} 
                          alt={`Feature: ${feature.title}`} // Example alt text
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <video 
                          src={feature.media} 
                          autoPlay 
                          muted 
                          loop 
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>
      </Element>

      {/* How It Works Section */}
      <Element name="how-it-works">
        <section id="how-it-works" className="py-24 bg-[#eff1ff]">
          <div className="max-w-4xl mx-auto px-2 sm:px-4 lg:px-6">
            <motion.h3 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold text-center mb-16 text-[#1e293b]"
            >
              <span className="text-[#3b82f6]">Effortless</span> Video Creation in Three Steps
            </motion.h3>
            <motion.div 
              variants={staggerChildren}
              initial="hidden"
              whileInView="visible"
              className="space-y-12"
            >
              {[
                { step: 1, title: "Upload Your Own Video or Provide a Loom Recording Link", desc: "Choose your preferred method to bring your content to life" },
                { step: 2, title: "Select Voice & Edit Transcript", desc: "Choose the voice and edit the transcript with cleaned up 'uhms' and improved grammar" },
                { step: 3, title: "Download & Share", desc: "Get your polished video with professional, human-like AI voiceovers, then download and share" },
              ].map((item, index) => (
                <motion.div key={index} variants={fadeIn} className="flex items-center">
                  <div className="flex-shrink-0 mr-8">
                    <div className="w-16 h-16 rounded-full bg-[#3b82f6] text-white flex items-center justify-center text-2xl font-bold">
                      {item.step}
                    </div>
                  </div>
                  <div>
                    <h4 className="text-2xl md:text-3xl font-semibold mb-2 text-[#1e293b]">{item.title}</h4>
                    <p className="text-lg text-[#334155] font-light">{item.desc}</p>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>
      </Element>

      {/* Loom Integration Highlight */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-2 sm:px-4 lg:px-6 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h3 className="text-3xl md:text-4xl font-bold mb-6 text-[#0f172a]">
              <span className="text-[#3b82f6]">Elevate</span> Your Loom Recordings
            </h3>
            <p className="text-xl md:text-2xl mb-8 text-[#334155] font-light">
              Transform your Loom screen recordings into polished, professional videos with just a few clicks. Seamlessly import and enhance your content.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSignIn}
              className="bg-[#3b82f6] text-white px-8 py-3 rounded-full text-lg font-bold hover:bg-[#2563eb] transition duration-300"
            >
              Upgrade Your Loom Videos
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* Pricing Section */}
      <PricingSection />

      {/* Call to Action */}
      <section className="py-32 bg-[#1e3a8a]">
        <motion.div 

          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
        >
          <h3 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            <span className="text-[#60a5fa]">Revolutionize</span> Your Video Communication
          </h3>
          <p className="text-xl md:text-2xl mb-10 text-gray-300 max-w-2xl mx-auto font-light">
            Start for free and pay only for what you use. No subscriptions, no commitments.
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSignIn} 
            className="bg-[#60a5fa] text-[#1e3a8a] px-10 py-4 rounded-full text-xl font-bold hover:bg-white transition duration-300"
          >
            Get Loomos for Free
          </motion.button>
        </motion.div>
      </section>

      {/* Voice Cloning Section */}
      <VoiceCloningSection />

      {/* Footer */}
      <footer className="bg-[#f8fafc] text-[#334155]">
        <div className="max-w-custom mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center mb-4 md:mb-0">
              <MagicWandIcon size={24} className="text-[#3b82f6] mr-2" />
              <span className="text-xl font-semibold text-[#0f172a]">Loomos</span>
            </div>
            <div className="flex space-x-6">
              <Link to="/privacy" className="hover:text-[#3b82f6] transition duration-300">Privacy</Link>
              <a href="#" className="hover:text-[#3b82f6] transition duration-300">Terms</a>
              <a href="mailto:help@loomos.co" className="hover:text-[#3b82f6] transition duration-300">Contact: help@loomos.co</a>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm">&copy; 2024 Loomos. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
