import React from 'react';
import ReactPlayer from 'react-player';
import { Upload, Edit, Video } from 'lucide-react';

const TutorialPage = () => {
  const steps = [
    { icon: <Upload size={24} />, text: "Upload video" },
    { icon: <Edit size={24} />, text: "Customize Audio & Visuals" },
    { icon: <Video size={24} />, text: "Get Studio-Quality Video" },
  ];

  return (
    <div className="h-screen flex flex-col px-4 sm:px-6 lg:px-8 pb-8 max-w-7.5xl mx-auto">
      <div className="flex-grow flex flex-col items-center justify-center">
        <div className="w-full max-w-4xl mb-12">
          <div className="bg-white rounded-2xl shadow-lg p-10 border border-gray-100">
            <h2 className="text-3xl font-light text-purple-600 mb-8 text-center">How to Use Loomos</h2>
            <div className="aspect-w-16 aspect-h-9 mb-10">
              <ReactPlayer
                url={process.env.PUBLIC_URL + '/tutorial.mp4'}
                width="100%"
                height="100%"
                controls={true}
                playing={false} // This enables autoplay
                muted={false} // This is necessary for autoplay to work in most browsers
                className="rounded-lg shadow-sm"
              />
            </div>
          </div>
        </div>

        {/* How it works section */}
        <div className="w-full max-w-4xl mt-12 bg-gray-50 rounded-lg p-6">
          <h3 className="text-xl font-extrabold text-purple-600 mb-6 text-center">How it works ✨</h3>
          <div className="flex justify-between items-center">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center w-1/3">
                <div className="rounded-full p-3 mb-3 bg-gray-100 text-gray-400 transition-all duration-300">
                  {step.icon}
                </div>
                <p className="text-center text-sm font-light text-gray-600">{step.text}</p>
                <div className="w-full h-0.5 bg-gray-200 mt-3"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialPage;