import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Rocket, RefreshCw, AlertCircle, Edit } from 'lucide-react';
import axios from 'axios';
import config from '../config';

const PublishButton = ({ user, uploadedVideoId, selectedLanguage, selectedVoice, transcript, setProjectTitle, projectTitle, backgroundImageId, musicVolume, musicID, setDubbedVideoId, publishingStatus, setPublishingStatus }) => {
  const [showTitleConfirm, setShowTitleConfirm] = React.useState(false);
  const [tempTitle, setTempTitle] = React.useState(projectTitle);

  const handlePublish = async () => {
    if (!showTitleConfirm) {
      setShowTitleConfirm(true);
      setTempTitle(projectTitle);
      return;
    }

    try {
      setPublishingStatus('processing');
      setShowTitleConfirm(false);
      const idToken = await user.getIdToken();
      
      // First, make a video dub async request
      const dubResponse = await axios.post(`${config.API_BASE_URL}/video-dub-async`, {
        video_id: uploadedVideoId,
        modifier: {
          language: { id: parseInt(selectedLanguage) },
          voice_id: selectedVoice,
          transcript: transcript.map((paragraph) => ({
            start: paragraph.start,
            end: paragraph.end,
            text: paragraph.text.trim(),
          })),
          music_id: musicID,
          music_volume: musicVolume,
        },
        title: tempTitle,
        background_image_id: backgroundImageId,
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });

      const newDubbedVideoId = dubResponse.data.video_id;
      // setDubbedVideoId(newDubbedVideoId);

      // Check processing status until completed
      await checkProcessingStatus(newDubbedVideoId, idToken);

      // Once processing is complete, save the video
      const saveResponse = await axios.post(`${config.API_BASE_URL}/save-video/${newDubbedVideoId}`, {
        title: tempTitle,  // Add the project title to the save request
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      
      if (saveResponse.status === 200) {
        setPublishingStatus('completed');
        setTimeout(() => setPublishingStatus('idle'), 5000); // Reset after 5 seconds
      } else {
        throw new Error('Failed to publish video');
      }
    } catch (error) {
      setPublishingStatus('error');
      setTimeout(() => setPublishingStatus('idle'), 5000);
    }
  };

  const checkProcessingStatus = async (videoId, idToken) => {
    try {
      while (true) {  // Use a while loop for better control
        const response = await axios.get(`${config.API_BASE_URL}/is-processed/${videoId}`, {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data.is_processed) {
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } catch (error) {
      throw error;  // Rethrow the error to be caught in handlePublish
    }
  };

  return (
    <>
      <motion.button
        className={`flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 text-lg ${
          publishingStatus === 'processing' ? 'opacity-50 cursor-not-allowed' : ''
        } ${publishingStatus === 'error' ? 'bg-red-500' : ''}`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => handlePublish()}
        disabled={publishingStatus === 'processing'}
      >
        <span className="font-medium flex items-center">
          {publishingStatus === 'processing' ? (
            <>
              <RefreshCw size={20} className="mr-2 animate-spin" />
              Saving...
            </>
          ) : publishingStatus === 'error' ? (
            <>
              <AlertCircle size={20} className="mr-2" />
              Error
            </>
          ) : (
            <>
              <Rocket size={20} className="mr-2" />
              Save
            </>
          )}
        </span>
      </motion.button>

      <AnimatePresence>
        {showTitleConfirm && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.9, opacity: 0, y: 20 }}
              transition={{ type: "spring", damping: 15, stiffness: 300 }}
              className="bg-white rounded-xl shadow-2xl p-8 max-w-md w-full mx-4 relative overflow-hidden"
            >
              <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-indigo-500 to-purple-500" />
              <div className="flex items-center mb-6">
                <div className="bg-blue-100 rounded-full p-2 mr-4">
                  <Edit className="text-blue-500" size={28} />
                </div>
                <h3 className="text-2xl font-semibold text-gray-800">
                  Confirm Video Title
                </h3>
              </div>
              <input
                type="text"
                value={tempTitle}
                onChange={(e) => setTempTitle(e.target.value)}
                className="w-full px-4 py-2 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter video title"
                autoFocus
              />
              <div className="flex space-x-4">
                <motion.button
                  className="flex-1 bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setProjectTitle(tempTitle);
                    handlePublish();
                  }}
                >
                  Confirm
                </motion.button>
                <motion.button
                  className="flex-1 bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowTitleConfirm(false)}
                >
                  Cancel
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PublishButton;
