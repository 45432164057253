import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { useAuth } from '../AuthProvider';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { format, subDays, startOfWeek, isWithinInterval } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const { user } = useAuth();
  const [usersData, setUsersData] = useState(null);
  const [videosData, setVideosData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const token = await user.getIdToken();
        const [usersResponse, videosResponse] = await Promise.all([
          fetch('/api/analytics/users', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }),
          fetch('/api/analytics/videos', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        ]);

        if (!usersResponse.ok || !videosResponse.ok) {
          throw new Error('Failed to fetch analytics data');
        }

        const users = await usersResponse.json();
        const videos = await videosResponse.json();

        setUsersData(users);
        setVideosData(videos);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchAnalytics();
    }
  }, [user]);

  const calculateDAU = () => {
    if (!usersData?.users) return { labels: [], data: [] };

    const today = new Date();
    const last30Days = Array.from({ length: 30 }, (_, i) => {
      const date = subDays(today, i);
      const dateStr = format(date, 'MMM dd');
      const activeUsers = usersData.users.filter(user => {
        const loginDate = new Date(user.last_login);
        return format(loginDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
      }).length;
      return { date: dateStr, count: activeUsers };
    }).reverse();

    return {
      labels: last30Days.map(d => d.date),
      data: last30Days.map(d => d.count)
    };
  };

  const calculateWAU = () => {
    if (!usersData?.users) return { labels: [], data: [] };

    const today = new Date();
    const last12Weeks = Array.from({ length: 12 }, (_, i) => {
      const weekEnd = subDays(today, i * 7);
      const weekStart = startOfWeek(weekEnd);
      const weekStr = `Week ${format(weekStart, 'MM/dd')}`;
      const activeUsers = usersData.users.filter(user => {
        const loginDate = new Date(user.last_login);
        return isWithinInterval(loginDate, { start: weekStart, end: weekEnd });
      }).length;
      return { week: weekStr, count: activeUsers };
    }).reverse();

    return {
      labels: last12Weeks.map(w => w.week),
      data: last12Weeks.map(w => w.count)
    };
  };

  const calculateSubscriptionDistribution = () => {
    if (!usersData?.users) return { labels: [], data: [] };

    const distribution = usersData.users.reduce((acc, user) => {
      acc[user.subscription_tier] = (acc[user.subscription_tier] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(distribution),
      data: Object.values(distribution)
    };
  };

  const calculateVideoMetrics = () => {
    if (!videosData?.videos) return { labels: [], data: [] };

    const today = new Date();
    const last30Days = Array.from({ length: 30 }, (_, i) => {
      const date = subDays(today, i);
      const dateStr = format(date, 'MMM dd');
      const dailyVideos = videosData.videos.filter(video => {
        const videoDate = new Date(video.created_at);
        return format(videoDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
      }).length;
      return { date: dateStr, count: dailyVideos };
    }).reverse();

    return {
      labels: last30Days.map(d => d.date),
      data: last30Days.map(d => d.count)
    };
  };

  const calculateUsersWithVideos = () => {
    if (!usersData?.users) return { labels: [], data: [] };

    // Sort users by creation date
    const usersWithVideos = usersData.users
      .filter(user => user.videos_used > 0)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    const today = new Date();
    const last30Days = Array.from({ length: 30 }, (_, i) => {
      const date = subDays(today, i);
      const dateStr = format(date, 'MMM dd');
      // Count all users who have used videos and were created before or on this date
      const totalUsersWithVideos = usersWithVideos.filter(user => 
        new Date(user.created_at) <= date
      ).length;
      return { date: dateStr, count: totalUsersWithVideos };
    }).reverse();

    return {
      labels: last30Days.map(d => d.date),
      data: last30Days.map(d => d.count)
    };
  };

  if (loading) return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-red-500 text-lg">Error: {error}</div>
    </div>
  );

  const dauData = calculateDAU();
  const wauData = calculateWAU();
  const subscriptionData = calculateSubscriptionDistribution();
  const videoMetrics = calculateVideoMetrics();
  const usersWithVideosData = calculateUsersWithVideos();

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Analytics Dashboard</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Daily Active Users (Last 30 Days)
          </h2>
          <div className="h-[300px]">
            <Line
              data={{
                labels: dauData.labels,
                datasets: [{
                  label: 'DAU',
                  data: dauData.data,
                  borderColor: 'rgb(75, 192, 192)',
                  tension: 0.1
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Weekly Active Users (Last 12 Weeks)
          </h2>
          <div className="h-[300px]">
            <Line
              data={{
                labels: wauData.labels,
                datasets: [{
                  label: 'WAU',
                  data: wauData.data,
                  borderColor: 'rgb(153, 102, 255)',
                  tension: 0.1
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Subscription Distribution
          </h2>
          <div className="h-[300px]">
            <Bar
              data={{
                labels: subscriptionData.labels,
                datasets: [{
                  label: 'Users',
                  data: subscriptionData.data,
                  backgroundColor: 'rgb(255, 99, 132)',
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Videos Created (Last 30 Days)
          </h2>
          <div className="h-[300px]">
            <Line
              data={{
                labels: videoMetrics.labels,
                datasets: [{
                  label: 'Videos',
                  data: videoMetrics.data,
                  borderColor: 'rgb(54, 162, 235)',
                  backgroundColor: 'rgba(54, 162, 235, 0.1)',
                  fill: true,
                  tension: 0.1
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1
                    }
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        return `Videos: ${context.parsed.y}`;
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Total Users with Videos Over Time
          </h2>
          <div className="h-[300px]">
            <Line
              data={{
                labels: usersWithVideosData.labels,
                datasets: [{
                  label: 'Total Users with Videos',
                  data: usersWithVideosData.data,
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.1)',
                  fill: true,
                  tension: 0.1
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1
                    }
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        return `Total Users: ${context.parsed.y}`;
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics; 