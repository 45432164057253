import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const VideoComparison = ({ 
  languages, 
  beforeVideoSrc, 
  beforeThumbnailSrc, 
  getAfterVideoSrc, 
  getAfterThumbnailSrc, 
  title 
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].code);
  const beforeVideoRef = useRef(null);
  const afterVideoRef = useRef(null);

  const playAfterVideo = (langCode) => {
    if (afterVideoRef.current) {
      beforeVideoRef.current?.pause();
      afterVideoRef.current.src = getAfterVideoSrc(langCode);
      afterVideoRef.current.play();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => entries.forEach(entry => {
        if (!entry.isIntersecting) {
          beforeVideoRef.current?.pause();
          afterVideoRef.current?.pause();
        }
      })
    );

    [beforeVideoRef, afterVideoRef].forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      [beforeVideoRef, afterVideoRef].forEach(ref => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="w-full pb-10 pt-20 bg-[#eff1ff]"
    >      
      <div className="text-center space-y-6 mb-16">
      <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl font-bold text-center mb-8 text-[#1e293b]"
          >
            <span className="text-[#3b82f6]">Forget</span> the Retakes and Scripts.
          </motion.h2>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left column - Before video */}
          <div className="space-y-4">
            <p className="text-2xl md:text-3xl font-bold text-gray-900 text-center mb-6">
              Before
            </p>
            <div className="aspect-video rounded-2xl overflow-hidden bg-gray-900/5 shadow-lg ring-1 ring-gray-200">
              <video 
                ref={beforeVideoRef}
                className="w-full h-full object-cover" 
                poster={beforeThumbnailSrc}
                controls
              >
                <source src={beforeVideoSrc} type="video/mp4" />
              </video>
            </div>
          </div>

          {/* Right column - After video and languages */}
          <div className="space-y-6">
            <div className="space-y-4">
              <p className="text-2xl md:text-3xl font-bold text-gray-900 text-center mb-6">
                After
              </p>
              <div className="aspect-video rounded-2xl overflow-hidden bg-gray-900/5 shadow-lg ring-1 ring-gray-200">
                <video 
                  ref={afterVideoRef}
                  className="w-full h-full object-cover"
                  poster={getAfterThumbnailSrc(selectedLanguage)}
                  controls
                >
                  <source src={getAfterVideoSrc(selectedLanguage)} type="video/mp4" />
                </video>
              </div>
            </div>

            {/* Language selector */}
            <div className="flex flex-wrap gap-2 justify-center pt-2">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => {
                    setSelectedLanguage(lang.code);
                    playAfterVideo(lang.code);
                  }}
                  className={`px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ${
                    selectedLanguage === lang.code 
                      ? 'bg-gray-900 text-white shadow-lg scale-105' 
                      : 'bg-white text-gray-700 hover:bg-gray-50 shadow-sm border border-gray-200'
                  }`}
                >
                  <span className="mr-2">{lang.flag}</span>
                  {lang.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default VideoComparison;
