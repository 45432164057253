import { useEffect } from 'react';

const useTawkTo = () => {
  useEffect(() => {
    // Create Tawk_API if it doesn't exist
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Create and load the script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/671fc80f2480f5b4f59535f3/1iba2avd0';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append the script to the document
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useTawkTo;

