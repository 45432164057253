import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, PlayCircle, Share2, Check, Download, Code, X, Clock } from 'lucide-react';
import VideoPlayer from './VideoPlayer';
import useTawkTo from '../hooks/useTawkTo';

const LibraryView = ({ user, openSidePanel }) => {
  useTawkTo();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [processedVideos, setProcessedVideos] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState({});
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [copiedVideoId, setCopiedVideoId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [downloadingVideoId, setDownloadingVideoId] = useState(null);

  useEffect(() => {
    const fetchProcessedVideos = async () => {
      if (!user) return;
      try {
        const idToken = await user.getIdToken();
        const response = await axios.get(`${config.API_BASE_URL}/processed-videos/${user.uid}`, {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data && Array.isArray(response.data.videos)) {
          setProcessedVideos(response.data.videos);
          response.data.videos.forEach(video => {
            fetchThumbnailUrl(video.parent_id);
          });
        } else {
          console.error('Unexpected response format:', response.data);
          setProcessedVideos([]);
        }
      } catch (error) {
        console.error('Error fetching processed videos:', error);
        setProcessedVideos([]);
      }
    };

    if (user) {
      fetchProcessedVideos();
    }
  }, [user]);

  useEffect(() => {
    setFilteredVideos(
      processedVideos.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, processedVideos]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePlayVideo = (videoId) => {
    setSelectedVideoId(videoId);
  };

  const handleCloseModal = () => {
    setSelectedVideoId(null);
  };

  const fetchThumbnailUrl = async (videoId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/thumbnail/${videoId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'image/jpeg'
        },
        responseType: 'blob'
      });
      const url = URL.createObjectURL(response.data);
      setThumbnailUrls(prevState => ({ ...prevState, [videoId]: url }));
    } catch (error) {
      console.error('Error fetching thumbnail:', error);
    }
  };

  const handleShareVideo = (videoId) => {
    const shareUrl = `${window.location.origin}/shared/${videoId}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopiedVideoId(videoId);
      setShowNotification(true);
      setTimeout(() => {
        setCopiedVideoId(null);
        setShowNotification(false);
      }, 3000); // Hide after 3 seconds
    }).catch((err) => {
      console.error('Failed to copy share link: ', err);
    });
  };

  const handleDownload = async (videoId, title) => {
    try {
      setDownloadingVideoId(videoId); // Set downloading state
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/stream-video/${videoId}`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading video:', error);
    } finally {
      setDownloadingVideoId(null); // Reset downloading state
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (openSidePanel && typeof openSidePanel === 'function') {
      openSidePanel();
    }
  }, [openSidePanel]);

  return (
    <div className="max-w-6xl mx-auto space-y-8 p-6">
      <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text">My Library</h2>
      
      <div className="relative">
        <input
          type="text"
          placeholder="Search videos..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-4 pl-12 pr-4 text-gray-700 bg-white border-2 border-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
        />
        <Search className="absolute left-4 top-4 text-gray-400" size={20} />
      </div>

      <AnimatePresence>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {filteredVideos.map((video) => (
            <motion.div
              key={video.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl"
              whileHover={{ scale: 1.03, y: -5 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="relative aspect-video">
                <img
                  src={thumbnailUrls[video.parent_id]}
                  alt="Video thumbnail"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <button
                    className="absolute inset-0 flex items-center justify-center"
                    onClick={() => handlePlayVideo(video.video_id)}
                  >
                    <PlayCircle className="text-white" size={64} />
                  </button>
                </div>
                <div className="absolute bottom-2 right-2 bg-black bg-opacity-80 text-white px-1.5 py-0.5 rounded-md text-xs flex items-center">
                  <Clock size={10} className="mr-1" />
                  {formatDuration(video.duration)}
                </div>
              </div>
              <div className="p-3 flex justify-between items-center">
                <h3 className="text-sm font-medium text-gray-800 truncate flex-grow mr-2">{video.title}</h3>
                <div className="flex items-center space-x-2">
                  <motion.button
                    className={`p-1.5 rounded-full transition-all duration-300 ${
                      copiedVideoId === video.video_id
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-100 text-indigo-600 hover:bg-indigo-100'
                    }`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleShareVideo(video.video_id)}
                  >
                    {copiedVideoId === video.video_id ? (
                      <Check size={16} />
                    ) : (
                      <Share2 size={16} />
                    )}
                  </motion.button>
                  <motion.button
                    className="p-1.5 rounded-full bg-gray-100 text-indigo-600 hover:bg-indigo-100 transition-all duration-300"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleDownload(video.video_id, video.title)}
                    disabled={downloadingVideoId === video.video_id}
                  >
                    {downloadingVideoId === video.video_id ? (
                      <div className="animate-spin">
                        <Clock size={16} />
                      </div>
                    ) : (
                      <Download size={16} />
                    )}
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>

      {filteredVideos.length === 0 && (
        <motion.p 
          className="text-center text-xl text-gray-600 mt-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          No videos found. Start creating your first project!
        </motion.p>
      )}

      <AnimatePresence>
        {selectedVideoId && (
          <motion.div 
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="relative bg-white rounded-2xl shadow-2xl p-8 w-full max-w-4xl max-h-[90vh] overflow-y-auto"
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              transition={{ type: "spring", damping: 15 }}
            >
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
                onClick={handleCloseModal}
              >
                <X size={32} />
              </button>
              <VideoPlayer user={user} videoId={selectedVideoId} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showNotification && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="fixed top-4 right-4 left-4 md:left-auto md:w-auto bg-white rounded-lg shadow-lg p-4 flex items-center justify-between z-50"
          >
            <div className="flex items-center space-x-2">
              <Check className="text-green-500 flex-shrink-0" size={20} />
              <span className="text-gray-800 font-medium">
                {copiedVideoId ? "Link copied to clipboard!" : "Embed code copied to clipboard!"}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {downloadingVideoId && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="fixed top-4 right-4 left-4 md:left-auto md:w-auto bg-white rounded-lg shadow-lg p-4 flex items-center justify-between z-50"
          >
            <div className="flex items-center space-x-2">
              <div className="animate-spin">
                <Clock className="text-indigo-500 flex-shrink-0" size={20} />
              </div>
              <span className="text-gray-800 font-medium">
                Preparing download, please wait...
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LibraryView;
