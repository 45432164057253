import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import LibraryView from './components/LibraryView';
import SidePanel from './components/SidePanel';
import LandingPage from './components/LandingPage';
import SharedVideoPage from './components/SharedVideoPage';
import NewProject from './components/NewProject';
import TutorialPage from './components/TutorialPage';
import FeedbackPage from './components/FeedbackPage';
import UpgradePlan from './components/UpgradePlan';
import PrivacyPolicy from './components/PrivacyPolicy';
// import Blog from './components/Blog';
// import BlogPost from './components/BlogPost';
import Analytics from './components/Analytics';

const VideoProcessingApp = () => {
  const { user, loading } = useAuth();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  console.log('Auth state:', { user, loading });

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  const openSidePanel = () => {
    setIsSidePanelOpen(true);
  };

  const handleLogout = () => {
    // Implement your logout logic here
  };

  if (loading) {
    console.log('Loading auth state...');
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  console.log('Rendering with user:', user);

  return (
    <div className="flex bg-gradient-to-br from-indigo-50 to-purple-50 min-h-screen font-circular">
      {user && isSidePanelOpen && <SidePanel onLogout={handleLogout} />}
      <div className="flex-grow overflow-y-auto">
        <Routes>
          <Route path="/shared/:videoId" element={<SharedVideoPage />} />
          <Route 
            path="/" 
            element={
              user ? (
                <NewProject user={user} closeSidePanel={closeSidePanel} openSidePanel={openSidePanel} />
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
          <Route 
            path="/library" 
            element={
              user ? (
                <LibraryView user={user} closeSidePanel={closeSidePanel} />
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
          <Route 
            path="/analytics" 
            element={
              user ? (
                <Analytics />
              ) : (
                console.log('Redirecting to login, user:', user) || 
                <Navigate to="/login" replace state={{ from: '/analytics' }} />
              )
            } 
          />
          <Route 
            path="/login" 
            element={
              user ? (
                console.log('Redirecting from login to home, user:', user) ||
                <Navigate to="/" replace />
              ) : (
                <LandingPage />
              )
            } 
          />
          <Route path="/tutorial" element={<TutorialPage />} />
          <Route path="/feedback" element={<FeedbackPage user={user} />} />
          <Route path="/upgrade" element={<UpgradePlan />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} /> */}
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <VideoProcessingApp />
    </Router>
  );
};

export default App;
