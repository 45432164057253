import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy for Loomos.co</h1>
      <p className="mb-4">Effective Date: 2024-10-18</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
      <p className="mb-4">Welcome to Loomos.co, where we help users transform rough screen recordings into professional studio-grade videos. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, store, and share your information when you use our services.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Information We Collect</h2>
      <p className="mb-4">We collect the following types of information:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Personal Information: When you sign up for an account on Loomos.co, we collect your name, email address, and other identifying details necessary for account creation and communication.</li>
        <li>Video Content: When you upload videos for editing, the rough video recordings and final edited videos are stored on our servers.</li>
        <li>Usage Data: We may collect information about how you use our platform, such as the pages you visit, the actions you take, and the time spent on the platform.</li>        
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. How We Use Your Information</h2>
      <p className="mb-4">We use your information for the following purposes:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>To provide and enhance our services, including processing and editing your videos.</li>
        <li>To personalize your experience on our platform by remembering your preferences and settings.</li>
        <li>To communicate with you regarding updates, account-related matters, or technical issues.</li>
        <li>To analyze usage trends to improve the platform's performance and features.</li>
        <li>To comply with legal obligations and protect our platform from fraudulent activities.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Video Storage and Processing</h2>
      <p className="mb-4">Videos that you upload to Loomos.co are stored on our secure servers and processed according to your instructions. Only authorized personnel have access to these videos for processing purposes. We do not share or distribute your videos with third parties unless legally required to do so.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Sharing Your Information</h2>
      <p className="mb-4">We do not sell, trade, or rent your personal or video data to third parties. We may share your information in the following circumstances:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Service Providers: We may share information with third-party service providers who help us operate our platform and deliver services (e.g., cloud storage providers).</li>
        <li>Legal Requirements: We may disclose your information if required by law, such as in response to a court order or regulatory request.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Data Security</h2>
      <p className="mb-4">We use a combination of physical, technical, and administrative safeguards to protect the data you share with us. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Data Retention</h2>
      <p className="mb-4">We retain your personal information and video content for as long as necessary to provide our services or comply with legal obligations. If you wish to delete your account or any data, you can contact us at help@loomos.co to request deletion.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Your Rights</h2>
      <p className="mb-4">Depending on your jurisdiction, you may have the right to:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Access, correct, or delete your personal information.</li>
        <li>Object to or restrict our processing of your personal data.</li>
        <li>Request portability of your data to another service provider.</li>
      </ul>
      <p className="mb-4">To exercise these rights, please contact us at help@loomos.co.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">9. Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this policy periodically to stay informed about how we protect your privacy.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">10. Contact Us</h2>
      <p className="mb-4">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p className="mb-4">Email: help@loomos.co</p>
    </div>
  );
};

export default PrivacyPolicy;
