import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Play, Pause, Volume2, Info } from 'lucide-react';
import config from '../config';

const MusicTab = ({
  musicList,
  setMusicID,
  setMusicVolume,
  initialVolume,
  initialMusicId
}) => {
  const [selectedMusic, setSelectedMusic] = useState(() => initialMusicId || null);
  const [volume, setVolume] = useState(() => Math.round((initialVolume || 0.1) * 100));
  const [playingMusic, setPlayingMusic] = useState(null);
  const audioRef = useRef(new Audio());
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  // Ref to track if handlePlay is in progress
  const isHandlePlayInProgress = useRef(false);

  // Handle volume changes
  const handleVolumeChange = useCallback(
    (newVolume) => {
      setVolume(newVolume);
      setMusicVolume(newVolume / 100);
      localStorage.setItem('musicVolume', newVolume.toString());
      if (audioRef.current) {
        audioRef.current.volume = newVolume / 100;
      }
    },
    [setMusicVolume]
  );

  // Initialize volume from props
  useEffect(() => {
    if (initialVolume !== undefined) {
      handleVolumeChange(Math.round(initialVolume * 100));
    }
  }, [initialVolume, handleVolumeChange]);

  // Update selected music in localStorage and notify parent
  useEffect(() => {
    if (selectedMusic !== null) {
      localStorage.setItem('selectedMusic', selectedMusic);
    } else {
      localStorage.removeItem('selectedMusic');
    }
    setMusicID(selectedMusic);
  }, [selectedMusic, setMusicID]);

  // Handle play/pause logic
  const handlePlay = async (musicId) => {
    // Prevent multiple handlePlay operations
    if (isHandlePlayInProgress.current) {
      return;
    }
    isHandlePlayInProgress.current = true;
    setIsLoading(true);

    try {
      if (playingMusic === musicId) {
        // Stop the currently playing audio
        audioRef.current.pause();
        audioRef.current.src = '';
        setPlayingMusic(null);
        setPlaybackProgress(0);
        setIsLoading(false);
      } else {
        // Stop any currently playing audio
        audioRef.current.pause();
        audioRef.current.src = '';
        setPlayingMusic(null);
        setPlaybackProgress(0);

        // Set the new playing music
        setPlayingMusic(musicId);
        setPlaybackProgress(0);

        // Set the audio source directly to the stream endpoint
        const streamUrl = `${config.API_BASE_URL}/stream-music/${musicId}`;

        audioRef.current.src = streamUrl;
        audioRef.current.volume = volume / 100;

        // Event listeners
        const handleTimeUpdate = () => {
          if (audioRef.current.duration) {
            setPlaybackProgress(audioRef.current.currentTime / audioRef.current.duration);
          }
        };

        const handleEnded = () => {
          setPlayingMusic(null);
          setPlaybackProgress(0);
          setIsLoading(false);
        };

        const handleError = (e) => {
          console.error("Audio playback error:", e);
          setPlayingMusic(null);
          setPlaybackProgress(0);
          setIsLoading(false);
        };

        audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.addEventListener('ended', handleEnded);
        audioRef.current.addEventListener('error', handleError);

        // Start playback
        await audioRef.current.play();
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error playing music:", error);
      setPlayingMusic(null);
      setPlaybackProgress(0);
      setIsLoading(false);
    } finally {
      isHandlePlayInProgress.current = false;
    }
  };

  // Handle music selection (without playing)
  const handleMusicSelect = (musicId) => {
    setSelectedMusic(musicId);
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  return (
    <div className="flex flex-col h-full rounded-lg p-6">
      <div className="flex items-center mb-4">
        <h2 className="text-lg font-bold text-gray-800">Background Music</h2>
        <div className="relative ml-2">
          <Info
            className="text-gray-500 cursor-pointer"
            size={18}
            onMouseEnter={() => setShowInfoTooltip(true)}
            onMouseLeave={() => setShowInfoTooltip(false)}
          />
          {showInfoTooltip && (
            <div className="absolute left-0 top-6 w-64 p-2 bg-white border border-gray-200 rounded shadow-lg z-10">
              <p className="text-sm text-gray-600">
                Select the soundtrack for your video. The chosen music will play softly in the background. 
                You can preview each track and adjust the volume to find the ideal balance for your video's ambiance.
              </p>
            </div>
          )}
        </div>
      </div>
      
      <div className="mb-6">
        <label htmlFor="volume-slider" className="flex items-center text-lg font-semibold text-gray-700 mb-2">
          <Volume2 className="mr-2" size={20} />
          Volume
        </label>
        <div className="flex items-center">
          <input
            type="range"
            id="volume-slider"
            min="0"
            max="100"
            value={volume}
            onChange={(e) => handleVolumeChange(parseInt(e.target.value, 10))}
            className="w-full mr-4"
          />
          <span className="text-gray-600 font-medium">{volume}%</span>
        </div>
      </div>

      <div className="flex-grow overflow-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
        <div className="grid grid-cols-1 gap-4">
          <div
            key="no-music"
            className={`p-4 rounded-lg cursor-pointer transition-all duration-200 ${
              selectedMusic === null
                ? 'bg-indigo-100 border-2 border-indigo-500'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
            onClick={() => handleMusicSelect(null)}
          >
            <div className="flex justify-between items-center">
              <span className="font-medium">No Background Music</span>
              {selectedMusic === null && (
                <span className="text-sm font-semibold text-indigo-600">Selected</span>
              )}
            </div>
          </div>
          {musicList.map(music => (
            <div
              key={music.id}
              className={`p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                playingMusic === music.id
                  ? 'bg-green-100 border-2 border-green-500'
                  : selectedMusic === music.id
                    ? 'bg-indigo-100 border-2 border-indigo-500'
                    : 'bg-gray-100 hover:bg-gray-200'
              }`}
              onClick={() => handleMusicSelect(music.id)}
            >
              <div className="flex flex-col">
                <div className="flex justify-between items-center">
                  <span className="font-medium">{music.name}</span>
                  <div className="flex items-center">
                    <button
                      className="flex items-center px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-all duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlay(music.id);
                      }}
                      title={playingMusic === music.id ? "Stop Preview" : "Preview"}
                      disabled={isHandlePlayInProgress.current}
                    >
                      {playingMusic === music.id ? <Pause size={14} className="mr-1" /> : <Play size={14} className="mr-1" />}
                      <span className="text-sm">{playingMusic === music.id ? "Stop" : "Preview"}</span>
                    </button>
                  </div>
                </div>
                {(playingMusic === music.id || selectedMusic === music.id) && (
                  <div className="mt-2">
                    {playingMusic === music.id && (
                      <progress value={playbackProgress} max="1" className="w-full"></progress>
                    )}                    
                  </div>
                )}
                {isLoading && playingMusic === music.id && (
                  <div className="mt-2">
                    <span className="text-sm text-gray-600">Loading...</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MusicTab;
