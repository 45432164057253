import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import config from './config';

const firebaseConfig = {
  apiKey: "AIzaSyDc9kw6TCmFZfKLQcAdG1aTAXcabTa0mYs",
  authDomain: "auth.loomos.co",
  projectId: "webdemo-c14f1",
  appId: "1:258839155127:web:695f651f7a37108a639832"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      await axios.post(`${config.API_BASE_URL}/signin`, { id_token: idToken });
      setUser(result.user);
      setError(null);
    } catch (error) {
      setError('Error signing in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      setError(null);
    } catch (error) {
      setError('Error signing out. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    error,
    loading,
    handleGoogleSignIn,
    handleSignOut
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
